import './normalize.css'
import './index.css'
import './Content.css'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'



function getTitle(data, pageTitle) {
  if (pageTitle) {
    return `${pageTitle} | ${data.site.siteMetadata.title}`
  }
  return data.site.siteMetadata.title;
}

const Layout = ({ children, useHeroHeader, pageTitle }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={getTitle(data, pageTitle)}
          meta={[
            { name: 'description', content: 'Turn based RPG in the world of startups, computer science, and hacking' },
            { name: 'keywords', content: 'The Danger Crew, Game, RPG, Web, React, JavaScript' },
          ]}
        >
          <html lang="en" />
          <meta name="google-site-verification" content="_7rDkrDz-NOOlmOx7bT9a3y2mMzK8neEAGu7jfkX8Tg" />
          <link href="https://fonts.googleapis.com/css?family=Dosis:400,700" rel="stylesheet" />
        </Helmet>
        {children}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
