import React from 'react'
import Layout from '../components/Layout'
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>Uh oh! This page is no longer here.</p>
    <Link className="link" to="/">Back to Home</Link>

  </Layout>
)

export default NotFoundPage
